import React, { Component } from "react";

import Section from "../components/Section";

class AboutSection extends Component {
  render() {
    return (
      <Section id="about" name="meme">
        About section
      </Section>
    );
  }
}

export default AboutSection;
